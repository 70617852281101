<template>
  <v-list dark rounded>
    <template v-for="(item, i) in items">
      <v-list-group
        v-if="item.active && item.items"
        :key="i"
        v-bind:group="item.group"
        :prepend-icon="item.icon"
        :style="mini ? 'padding: 0 8px; width:100%;' : 'width:100%;'"
        :class="mini ? 'minivariant-list-group' : ''"
      >
        <template v-slot:activator>
          <v-subheader
            v-if="item.header && !mini"
            :key="i"
            class="pl-0 theme--dark"
            style="margin-right:auto;"
          >
            {{ item.header }}
          </v-subheader>
          <v-list-item
            v-else-if="!mini"
            :to="item.href"
            :title="item.title"
            class="pa-0"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-subheader
            v-else
            :title="item.title"
            class="pl-0 theme--dark"
          >
            <v-icon v-html="item.icon" dark></v-icon>
          </v-subheader>
        </template>
        <!-- Sous menus -->
        <v-list-item
          v-for="subItem in item.items"
          :key="subItem.href"
          :to="subItem.href"
          :title="subItem.title"
          v-bind:router="!subItem.target"
          ripple
          v-bind:disabled="subItem.disabled"
          v-bind:target="subItem.target"
          :style="mini ? 'padding: 0 0px;' : ''"
        >
          <v-list-item-action v-if="subItem.active && subItem.icon">
            <v-icon v-html="subItem.icon" dark></v-icon>
          </v-list-item-action>
          <v-list-item-content v-if="subItem.active">
            <v-list-item-title>{{ subItem.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <!-- Divider -->
      <v-divider v-else-if="item.active && item.divider" :key="i"></v-divider>
      <!-- Sous titre -->
      <v-subheader
        v-else-if="item.active && item.header && !mini"
        :key="i"
        class="theme--dark"
      >
        {{ item.header }}
      </v-subheader>
      <v-list-item
        v-else-if="item.active && !item.header"
        :key="i"
        :to='item.href'
        :title="item.title"
        :style="(mini) ? 'padding: 0 8px;' : ''"
      >
        <v-list-item-action>
          <v-icon v-html="item.icon" dark></v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import navigation from '@/services/navigation'
import { mapState } from 'vuex'

export default {
  props: {
    mini: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    items () {
      navigation.forEach((element) => {
        // set active flag from user role
        element.active = element.role.includes(this.user.usr_role)
        // set active flag for page children
        const subpages = element?.items || []
        subpages.forEach((sub) => {
          sub.active = sub.role.includes(this.user.usr_role)
        })
      })
      return navigation
    },
    ...mapState({
      user: state => state.user.user
    })
  }
}
</script>

<style>
.minivariant-list-group > div {
  padding-left: 0 !important;
}
</style>
