<template>
  <v-menu
    v-model="open"
    :close-on-content-click="false"
    :nudge-width="300"
    offset-y
    z-index="900"
    width="500"
    max-width="500"
    rounded="rounded-lg"
    content-class="rounded-lg"
  >
    <template v-slot:activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip, attrs }">
          <v-btn
            text
            v-on="{ ...onTooltip, ...onMenu }"
            style="text-transform: capitalize;"
          >
            <v-icon left>mdi-information-outline</v-icon>
            {{ $APP_VER }}
          </v-btn>
        </template>
        <span>Modifications de versions</span>
      </v-tooltip>
    </template>
    <v-sheet>
      <v-expansion-panels accordion>
        <v-expansion-panel
          v-for="(item,i) in versions"
          :key="i"
        >
          <v-expansion-panel-header>
            <div>
              <span class="mr-2">{{ item.ver_version }}</span>
              <v-chip pill small>{{ $date(item.ver_date).format('DD/MM/YYYY') }}</v-chip>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <CardProperty
              v-for="(change, i) in item.ver_changelog"
              :key="i"
              :label="change.title"
              :value="change.description"
              class="my-1"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-sheet>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import CardProperty from '@/components/ui/CardProperty'

export default {
  components: { CardProperty },
  data () {
    return {
      open: false
    }
  },
  computed: {
    ...mapGetters({
      versions: 'app/versions'
    })
  }
}
</script>
