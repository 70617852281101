<template>
  <v-dialog
    v-model="open"
    width="600"
    persistent
    style="z-index:20001;"
  >
      <TaskView
        v-if="task"
        :task="task"
        @close="close"
      />
  </v-dialog>
</template>
<script>
import TaskView from '@/components/tasks/TaskView'
import TaskMixin from '@/mixins/TaskMixin'

export default {
  components: { TaskView },
  mixins: [TaskMixin],
  props: {
    task: {
      type: [Boolean, Object],
      required: false,
      default: () => false
    }
  },
  data () {
    return {
      open: false
    }
  },
  watch: {
    task () {
      this.task ? this.show() : this.close()
    }
  },
  methods: {
    show () {
      this.open = true
    },
    close () {
      this.removeTask()
      this.open = false
    }
  }
}
</script>
