<template>
  <v-app-bar
    app
    dense
    color="white"
    light
    short
    elevation="1"
    style="z-index:100 !important;"
  >
    <v-tooltip bottom open-delay="1500">
      <template v-slot:activator="{ on }">
        <v-btn
          text
          :ripple="false"
          v-on="on"
          @click.stop="switchMenu()"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>
      <span>Ouvrir / fermer le menu</span>
    </v-tooltip>
    <v-tooltip bottom open-delay="1500">
      <template v-slot:activator="{ on }">
        <v-icon
          v-on="on"
          class="pt-1 pr-1 hidden-sm-and-up"
          @click.stop="toggleDrawer"
        >
          mdi-chevron-right
        </v-icon>
      </template>
      <span>Ouvrir / fermer le menu</span>
    </v-tooltip>
    <FiltersMenu />
    <v-spacer></v-spacer>
    <VersionMenu />
    <UserMenu />
  </v-app-bar>
</template>

<script>
import FiltersMenu from '@/components/ui/FiltersMenu'
import UserMenu from '@/components/ui/UserMenu'
import VersionMenu from '@/components/ui/VersionMenu'

export default {
  components: {
    UserMenu,
    FiltersMenu,
    VersionMenu: VersionMenu
  },
  props: {
    mini: {
      type: Boolean,
      required: true
    },
    drawer: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    switchMenu() {
      if (this.$vuetify.breakpoint.width < 600) {
        this.toggleDrawer()
      } else {
        this.toggleMini()
      }
    },
    toggleDrawer () {
      this.$emit('update:drawer', !this.drawer)
    },
    toggleMini () {
      this.$emit('update:mini', !this.mini)
    }
  }
}
</script>
