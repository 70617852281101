<template>
  <div>
    <v-app-bar
      dense
      dark
      color="primary"
    >
      <v-toolbar-title class="font-weight-bold">
        Voir la tâche #{{ task.tsk_id }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>
    <div>
      <v-sheet>
        <v-tabs v-model="tab" fixed-tabs>
          <v-tab class="text-uppercase">Informations</v-tab>
          <!--
          <v-tab class="text-uppercase">
            <v-badge inline :content="task.users.length" :value="task.users.length">Agents</v-badge>
          </v-tab>
          <v-tab class="text-uppercase">
            <v-badge inline :content="getTotalTime(task)">Temps</v-badge></v-tab>
          -->
          <v-tab class="text-uppercase" @change="tabFileActive = true">Fichiers</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <TaskViewDetails :task="getViewableTask(task)" @close="close" />
          </v-tab-item>
          <!--
          <v-tab-item>
            <TaskViewUsers :task="getViewableTask(task)" @close="close" />
          </v-tab-item>
          <v-tab-item>
            <TaskViewTime :task="getViewableTask(task)" @close="close" />
          </v-tab-item>
          -->
          <v-tab-item>
            <DocumentTab  v-if="tabFileActive" :doc_xxx_id="task.tsk_id" :table_id="'tsk'"></DocumentTab>
          </v-tab-item>
        </v-tabs-items>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import TaskViewDetails from '@/components/tasks/TaskViewDetails'
//import TaskViewUsers from '@/components/tasks/TaskViewUsers'
//import TaskViewTime from '@/components/tasks/TaskViewTime'
import TaskMixin from '@/mixins/TaskMixin'
import DocumentTab from '@/components/documents/DocumentList.vue'

export default {
  components: {
    TaskViewDetails,
    //TaskViewUsers,
    //TaskViewTime,
    DocumentTab
  },
  mixins: [TaskMixin],
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      tab: 0,
      tabFileActive: false
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
