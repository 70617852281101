export default [
  {
    href: '/dashboard',
    title: 'Tableau de bord',
    icon: 'mdi-view-dashboard',
    role: ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE', 'INTERVENTION', 'CONSULTATION', 'SCE_CHIEFUNIT', 'SCE_MANAGER', 'SCE_ASSISTANT']
  },
  {
    href: '/map',
    title: 'Carte',
    icon: 'mdi-map-outline',
    role: ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE', 'INTERVENTION', 'CONSULTATION', 'SCE_CHIEFUNIT', 'SCE_MANAGER', 'SCE_ASSISTANT']
  },
  {
    href: '/tasks',
    title: 'Suivi des travaux',
    icon: 'mdi-traffic-cone',
    role: ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE', 'INTERVENTION', 'CONSULTATION', 'SCE_CHIEFUNIT', 'SCE_MANAGER', 'SCE_ASSISTANT']
  },
  {
    href: '/calendar',
    title: 'Calendrier',
    icon: 'mdi-calendar',
    role: ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE', 'INTERVENTION', 'CONSULTATION', 'SCE_CHIEFUNIT', 'SCE_MANAGER', 'SCE_ASSISTANT']
  },
  {
    href: '/users',
    title: 'Utilisateurs',
    icon: 'mdi-account-multiple-outline',
    role: ['ADMIN', 'CONDUCTEUR']
  },
  { divider: true, 'role': ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE', 'INTERVENTION', 'CONSULTATION'] },
  { 'header': 'Paramètres',   'title': 'Paramètres', 'icon': 'mdi-cogs', 'role': ['ADMIN', 'CONDUCTEUR'], 
    items: [
      { 'href': '/states',    'title': 'Gestion états', 'icon': 'mdi-tag-multiple', 'role': ['ADMIN', 'CONDUCTEUR'] },
      { 'href': '/types',     'title': 'Gestion des types', 'icon': 'mdi-format-list-bulleted-type', 'role': ['ADMIN', 'CONDUCTEUR'] },
    ]
  },
]
