<template>
  <v-app id="app" class="global-app">
    <v-navigation-drawer
      v-model="drawer"
      width="250"
      style="z-index:900 !important;"
      mobile-breakpoint="600"
      fixed
      :mini-variant="mini"
      :clipped="clipped"
      clipped-right
      app
      hide-overlay
      dark
      color="#0A2A35"
    >
      <v-toolbar
        flat
        dense
        short
        dark
        color="#0A2A35"
        :class="mini ? 'mb-5 toolbar-minivariant' : 'mb-5'"
      >
        <div class="d-flex justify-center align-center">
          <v-tooltip bottom open-delay="1500">
            <template v-slot:activator="{ on }">
              <img
                v-on="on"
                class="hidden-sm-and-up"
                width="35"
                alt=""
                src="/logo_small.svg"
                style="cursor:pointer;"
                @click.stop="drawer = !drawer"
              />
              <img
                v-on="on"
                class="hidden-xs-only"
                width="35"
                alt=""
                src="/logo_small.svg"
                style="cursor:pointer; margin-right: 16px;"
                @click.stop="mini = !mini"
              />
            </template>
            <span>Ouvrir / fermer le menu</span>
          </v-tooltip>
          <h3 class="d-inline-block">{{ title }}</h3>
        </div>
      </v-toolbar>
      <Sidebar :mini="mini" />
    </v-navigation-drawer>
    <Navbar
      :mini="mini"
      :drawer="drawer"
      @update:mini="setMini"
      @update:drawer="setDrawer"
    />
    <v-main style="background-color:#E5E5E5; position: relative;">
      <router-view :key="key" :contextStyle="contextStyle" />
    </v-main>
    <TaskEditModal v-if="permissions.tasks.update" :task="task" @updated="onUpdated" />
    <TaskViewModal v-else :task="task" />
    <ToastDialog ref="toast" />
    <ConfirmDial ref="confirm" />
    <!-- ------------Alerte view ----------------- -->
    <v-dialog v-model="modalEvent" max-width="900" style="z-index:2066;">
        <v-card min-width="350px" flat class="rounded-lg">
        <v-toolbar color="primary" dark flat height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>{{selectionEvent.title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modalEvent', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="elevation-0 pa-0">
            <EditEvent
                v-if="modalEvent" :switchModal="switchModal"
                :xxxId="selectionEvent.xxxId" :eveId="selectionEvent.eveId" 
                :eveTable="selectionEvent.eveTable" :eveType="selectionEvent.eveType" 
                :title="selectionEvent.title" :cooridnates="selectionEvent.cooridnates" />
        </v-card-text>
        </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Navbar from '@/components/ui/Navbar'
import Sidebar from  '@/components/ui/Sidebar'
import TaskEditModal from '@/components/tasks/TaskEditModal'
import TaskViewModal from '@/components/tasks/TaskViewModal'
import ConfirmDial  from '@/components/ui/ConfirmDialog.vue'
import ToastDialog  from '@/components/ui/Toast.vue'
import navigation from '@/services/navigation'
import { bus } from '@/plugins/bus'
import EditEvent  from '@/components/events/EventForm.vue'

export default {
  components: {
    TaskViewModal,
    TaskEditModal,
    Navbar,
    Sidebar,
    ConfirmDial,
    ToastDialog,
    EditEvent
  },
  data() {
    return {
      key: 0,
      drawer: true,
      mini: false,
      clipped: false,
      title: process.env.VUE_APP_TITLE,
      modalEvent: false,
      selectionEvent: { xxxId: 0, eveId:0, eveTable: "alt", eveType: "ALT", title:"Alerte", cooridnates:null },
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      task: state => state.tasks.task
    }),
    ...mapGetters({
      permissions: 'user/permissions'
    }),
    menuByRoute(){
      let menuByRoute = {}
      navigation.forEach( (element) => {
        element.active = true
        if( element.href ){ menuByRoute[element.href.split('/')[1] ] = element } // for pages
        if( element.items !== undefined ){
          element.items.forEach( (item) => {
            if( item.href ){ menuByRoute[item.href.split('/')[1]] = item } // for pages
          })
        }
      })
      return menuByRoute
    },
    contextStyle(){
      let objStyle = {
          title: '', icon: '',
          titleClass: 'pageTitleColor text-h5 font-weight-bold d-flex align-end', titleColor:'#005d7f', 
          titleIcon: 'pr-2',
          basePath: '',
          pathTab: [],
          breadcrumbs: []
      }
      let pathTab = this.$route.path.split('/')
      let path    = this.$route.path.split('/')[1]
      if( this.menuByRoute[path] ){
        objStyle.title    = this.menuByRoute[path].title
        objStyle.icon     = this.menuByRoute[path].icon
        objStyle.basePath = path
        objStyle.pathTab  = pathTab
        objStyle.sheet    = (pathTab.length > 2) ? true : false
        objStyle.breadcrumbs.push(
          { text: this.menuByRoute[path].title, disabled: false, href: '#/' + objStyle.basePath }
        )
        objStyle.breadcrumbs.push(
          { text: 'fiche', disabled: true, href: objStyle.basePath }
        )
      }
      return objStyle
    },
  },
  mounted: function (){
    this.$root.$confirm      = this.$refs.confirm.open // Global Confirm Dialog
    this.$root.$toast        = this.$refs.toast.open
    this.$root.$permissions  = this.permissions
    bus.$on('event:open', (obj) => {
      this.selectionEvent.eveId = obj.eve_id
      this.modalEvent = true
    })
  },
  methods: {
    setMini (mini) {
      this.mini = mini
    },
    setDrawer (drawer) {
      this.drawer = drawer
    },
    onUpdated () {
      return true
      //this.key++
    },
    toast (option) {
      if( option !== null && typeof option === 'object' ){
        var prop
        for( prop in this.toastDefault ){
            if( option[prop] !== undefined ){
                this.toastOption[prop] = option[prop]
            }
        }
      }
      this.enableToast = true
    },
    switchModal(name, mode){
        name    = name || 'dialogObj'
        mode    = mode || false
        this[name] = mode
        if( name == 'modalEvent' && !mode ){
            this.loadAlert = false
        }
    },
  }
}
</script>

<style lang="scss">
.toolbar-minivariant > div {
  padding-left: 9px !important;
}
</style>
