<template>
  <v-menu
    v-model="open"
    :close-on-content-click="false"
    :close-on-click="true"
    :nudge-width="300"
    offset-y
    z-index="1900"
    width="500"
    max-width="500"
    rounded="rounded-lg"
    content-class="rounded-lg"
  >
    <template v-slot:activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip, attrs }">
          <v-btn
            text
            v-on="{ ...onTooltip, ...onMenu }"
            v-bind="attrs"
          >
            <v-badge
              :value="conditions"
              :content="conditions || '0'"
              color="red"
              offset-y="10"
            >
              <v-icon>mdi-filter-outline</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <span>Filtre général</span>
      </v-tooltip>
    </template>
    <v-sheet style="max-height: 80vh; overflow-y: auto;">
      <v-app-bar
        color="primary"
        dark
        dense
        fixed
        class="elevation-0"
      >
        <v-app-bar-title class="font-weight-bold">
          Filtre général
        </v-app-bar-title>
        <v-spacer />
        <v-tooltip>
          <template v-slot:activator="{ on }">
            <v-app-bar-nav-icon v-on="on" @click="reset">
              <v-icon>mdi-undo</v-icon>
            </v-app-bar-nav-icon>
          </template>
          Réinitialiser
        </v-tooltip>
      </v-app-bar>
      <v-sheet>
        <div id="filter-dates">
          <div class="text-capitalize font-weight-bold pa-2 grey lighten-3">Dates</div>
          <v-divider />
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <DatePicker
                  :value="currentFilter.start"
                  label="Date de début"
                  :rules="rules.start"
                  required
                  @updated="setStartDate"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <DatePicker
                  :value="currentFilter.end"
                  label="Date de fin"
                  :rules="rules.end"
                  :min="currentFilter.start"
                  required
                  @updated="setEndDate"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-divider />
        </div>
        <div id="filter-actions">
          <div class="text-capitalize font-weight-bold pa-2 grey lighten-3">Actions</div>
          <v-divider />
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  label="Actions"
                  v-model="currentFilter.actions"
                  :items="types.filter(type => type.typ_id.substring(0, 3) == 'TYP')"
                  item-text="typ_label"
                  item-value="typ_id"
                  chips
                  deletable-chips
                  multiple
                  prepend-icon="mdi-account-hard-hat"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-divider />
        </div>
        <div id="filter-custom">
          <div class="text-capitalize font-weight-bold pa-2 grey lighten-3">Prestations</div>
          <v-divider />
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  label="Prestations normales"
                  :value="'0'"
                  v-model="currentFilter.custom"
                  prepend-icon="mdi-star-outline"
                  class="ma-0"
                />
                <v-checkbox
                  label="Prestations spéciales"
                  :value="'1'"
                  v-model="currentFilter.custom"
                  prepend-icon="mdi-star"
                  class="ma-0"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-divider />
        </div>
        <div id="filter-state">
          <div class="text-capitalize font-weight-bold pa-2 grey lighten-3">Etats</div>
          <v-divider />
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  v-for="state in states"
                  :key="state.sta_id"
                  :label="state.sta_label"
                  :value="state.sta_id"
                  v-model="currentFilter.states"
                  class="ma-0"
                >
                  <template #prepend>
                    <v-icon :color="state.sta_color">mdi-circle</v-icon>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div class="white" style="position: sticky; bottom: 0;">
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="close">
              Annuler
            </v-btn>
            <v-btn color="primary" @click="save">
              Appliquer
            </v-btn>
          </v-card-actions>
        </div>
      </v-sheet>
    </v-sheet>
  </v-menu>
</template>

<script>
import { validations } from '@/services/validation'
import { mapGetters, mapMutations } from 'vuex'
import DatePicker from '@/components/ui/DatePicker'
import { filterUpdateService } from '@/rxjsServices';

export default {
  components: {
    DatePicker
  },
  data () {
    return {
      open: false,
      currentFilter: {
        start: '',
        end: '',
        actions: [],
        custom: [],
        states: []
      }
    }
  },
  computed: {
    rules () {
      return validations.task.create
    },
    ...mapGetters({
      types: 'app/types',
      filter: 'app/filter',
      conditions: 'app/conditions',
      states: 'app/statesChoices'
    })
  },
  watch: {
    filter: {
      handler() {
        if (this.filter) {
          this.currentFilter = { ...this.filter }
        }
      },
      immediate: true
    }
  },
  methods: {
    close () {
      this.open = false
    },
    setStartDate (date) {
      this.currentFilter.start = date
    },
    setEndDate (date) {
      this.currentFilter.end = date
    },
    save () {
      this.setFilter({ ...this.currentFilter })
      filterUpdateService.sendFilter(this.currentFilter)
      this.close()
    },
    reset () {
      this.currentFilter = {
        start: '',
        end: '',
        actions: [],
        custom: [],
        states: []
      }
    },
    ...mapMutations({
      setFilter: 'app/SET_FILTER',
      resetFilter: 'app/RESET_FILTER'
    })
  }
}
</script>
