<template>
  <div>
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <div class="font-weight-bold">Début le</div>
              <div>{{ $date(task.start).format('DD/MM/YYYY') }}</div>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="font-weight-bold">Fin le</div>
              <div>{{ $date(task.end).format('DD/MM/YYYY') }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="font-weight-bold">Actions</div>
              <div>
                <ActionLabel v-for="(action, i) in task.actions" :key="i" :action="action" />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="font-weight-bold">Prestation spéciale</div>
              <div>
                {{ !!task.custom ? 'OUI' : 'NON' }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="font-weight-bold">Commentaire</div>
              <div>{{ task.comment || 'NON' }}</div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ActionLabel from '@/components/tasks/ActionLabel'

export default {
  components: {
    ActionLabel
  },
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  computed: {
    actions () {
      return this.types.filter(t => this.task.actions.includes(t.typ_id))
    },
    ...mapGetters({
      types: 'app/types'
    })
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
