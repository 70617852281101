<template>
  <v-menu
    v-model="open"
    offset-y
    transition="slide-x-transition"
    z-index="902"
    rounded="rounded-lg"
    content-class="rounded-lg"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="user"
        text
        v-on="on"
        style="text-transform: capitalize;"
      >
        <v-avatar>
          <v-icon dark class="ml-0">mdi-account-circle</v-icon>
        </v-avatar>
        {{ user.usr_firstName }}
        <v-icon right>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item link to="/account">
        <v-list-item-icon>
          <v-icon medium>mdi-account-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="'Mon profil'"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link @click.prevent="logout">
        <v-list-item-icon>
          <v-icon color="red">mdi-logout-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="'Se déconnecter'"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      open: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  methods: {
    async logout () {
      await this.removeAccessToken()
      await this.$router.push('/login')
    },
    ...mapActions({
      removeAccessToken: 'user/logout'
    })
  }
}
</script>
